import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { ContextProvider } from "../context"
import PopUp from './PopUp';
import MessageAlert from './MessageAlert';
import Overlay from './Overlay';
import Instructions from './Instructions';
import Keyboard from './Keyboard'
import MobileInstructions from './MobileInstructions';
import CrosswordLetter from './CrosswordLetter';
import Clue from './Clue'
import { submitPuzzle, getStats } from '../Api/api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleQuestion } from "@fortawesome/pro-light-svg-icons";
import { faCircleLeft, faCircleRight } from "@fortawesome/pro-solid-svg-icons";

import "../Styles/gameBoard.css"
import 'animate.css';
import "../Styles/crossword.css"
import "../Styles/clueTable.css"


const GameBoard = () => {

    const navigate = useNavigate()

    const { areCluesShown, areCluesShownCopy, riddleWord, puzzleAuthor, setAreCluesShown,
        setAreCluesShownCopy, setStateOrLocalStorage, isLoading,
        resetStateFromContext, setResetStateFromContext, setLocalStorage,
        puzzleNumber, newestPuzzleNumber, clues, question, width, height, releaseDate } = useContext(ContextProvider)

    const [crossword, setCrossword] = useState(setStateOrLocalStorage(new Array(riddleWord.length), "crossword"))
    const [crosswordCopy, setCrosswordCopy] = useState(setStateOrLocalStorage([], "crosswordCopy"))
    const [crosswordIndex, setCrosswordIndex] = useState(setStateOrLocalStorage(0, "crosswordIndex"))
    const [guess, setGuess] = useState(setStateOrLocalStorage({ isGuessMade: false, isCorrect: null }, "guess"))
    const [isSubmitEnabled, setIsSubmitEnabled] = useState(false)
    const [guessJustMade, setGuessJustMade] = useState(setStateOrLocalStorage(null, "guessJustMade"))
    const [guessesRemaining, setGuessesRemaining] = useState(setStateOrLocalStorage(3, "guessesRemaining"))
    const [isGameOver, setIsGameOver] = useState(setStateOrLocalStorage(false, "isGameOver"))
    const [isPopUpShown, setIsPopUpShown] = useState(setStateOrLocalStorage(false, "isPopUpShown"))
    const [isMessageAlertShown, setIsMessageAlertShown] = useState(setStateOrLocalStorage(false, "isMessageAlertShown"))
    const [isCrosswordRevealed, setIsCrosswordRevealed] = useState(setStateOrLocalStorage(false, "isCrosswordRevealed"))
    const [isSeeResultsShown, setIsSeeResultsShown] = useState(setStateOrLocalStorage(false, "isSeeResultsShown"))
    const [numberOfCluesRevealed, setNumberOfCluesRevealed] = useState(setStateOrLocalStorage(0, "numberOfCluesRevealed"))
    const [isOverlayShowing, setIsOverlayShowing] = useState(setStateOrLocalStorage(true, "isOverlayShowing"))
    const [areInstructionsShown, setAreInstructionsShown] = useState(setStateOrLocalStorage(true, "areInstructionsShown"))
    const [currentClue, setCurrentClue] = useState(setStateOrLocalStorage(0, "currentClue"))
    const [currentMaxClue, setCurrentMaxClue] = useState(setStateOrLocalStorage(0, "currentMaxClue"))
    const [isEndGameAnimationRunning, setIsEndGameAnimationRunning] = useState(setStateOrLocalStorage(false, "isEndGameAnimationRunning"))
    const [popUpMessage, setPopUpMessage] = useState(setStateOrLocalStorage("", "popUpMessage"))
    const [crosswordGuesses, setCrosswordGuesses] = useState(setStateOrLocalStorage({
        guess0: [],
        guess1: [],
        guess2: [],
        guess3: []
    }, "crosswordGuesses"))
    const [didUserFailPuzzle, setDidUserFailPuzzle] = useState(false);
    const [areStatsShown, setAreStatsShown] = useState(false);
    const [stats, setStats] = useState({
        failures: 10,
        submissions: 130,
        successes: {
            0: 10,
            1: 10,
            2: 10,
            3: 10,
            4: 10,
            5: 10,
            6: 10,
            7: 10,
            8: 10,
            9: 10,
            10: 10,
            11: 10,
        }
    })

    // localStorage.clear();

    const allowedKeyBoardEvent = ["a", "b", "c", "d", "e", "f", "g",
        "h", "i", 'j', "k", "l", "m", "n", "o", "p", "q", "r", "s",
        "t", "u", "v", "w", "x", "y", "z", "A", "B", "C", "D", "E",
        "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R",
        "S", "T", "U", "V", "W", "X", "Y", "Z", "1", "2", "3", "4", "5",
        "6", "7", "8", "9", "0", "Backspace", "ArrowLeft", "ArrowRight"]

    useEffect(() => {
        if (riddleWord !== "Loading")
            setCrossword(setStateOrLocalStorage(resizeArray(crossword, riddleWord.length, ""), "crossword"))
    }, [riddleWord])

    useEffect(() => {
        let count = 0
        crossword.forEach(letter => {
            if (letter) {
                count += 1
            }
        })
        // Get the last guessed word from local storage
        const lastGuessedWord = JSON.parse(localStorage.getItem("lastGuessedWord"))
        setIsSubmitEnabled(count === riddleWord.length)

    }, [riddleWord.length, crossword])

    useEffect(() => {
        if (!isMobile) {
            document.addEventListener('keydown', handleKeyDown)

            return () => {
                document.removeEventListener('keydown', handleKeyDown)
            }
        }
    }, [crosswordIndex, isLoading])

    useEffect(() => {
        handleWasGameAlreadyOver()
    }, [])

    useEffect(() => {
        if (resetStateFromContext) {
            resetState(riddleWord)
            setResetStateFromContext(false)
        }
    }, [resetStateFromContext])

    const resizeArray = (arr, newSize, defaultValue) => {
        while (newSize > arr.length)
            arr.push(defaultValue);
        arr.length = newSize;
        return arr;
    }

    // Checks if the user has already completed the game
    // And takes the appropriate actions if true
    const handleWasGameAlreadyOver = () => {
        // Check if the game is over from localStorage
        const localStorageValue = JSON.parse(localStorage.getItem("isGameOver"))
        // If the game is over
        if (localStorageValue === true) {
            // Set the currentClue and maxClue to the last clue
            setCurrentClue(11)
            setCurrentMaxClue(11)
            // Set all the clues to being shown
            setAreCluesShown([
                true, true, true, true, true,
                true, true, true, true, true,
                true, true, true, true, true,
                true, true, true, true, true,
            ])
            // See if the user failed the puzzle or completed the puzzle
            const isPuzzleSolved = JSON.parse(localStorage.getItem("result"))
            setGuessJustMade(false)
            // Let's say the user already played the game today and is coming back to check their results
            // We don't want to play all the animations for them again.
            // We just want to show them their results for that day
            // If the end of game animation is over, then the variable is false 
            // And we won't show anymore animations
            const shouldAnimationsBeShown = JSON.parse(localStorage.getItem("isEndGameAnimationRunning"))
            handleGameIsOver(isPuzzleSolved, shouldAnimationsBeShown)
        }
    }

    const areArraysEqual = (a, b) => {
        if (a === b) return true;
        if (a == null || b == null) return false;
        if (a.length !== b.length) return false;

        for (var i = 0; i < a.length; ++i) {
            if (a[i].toLowerCase() !== b[i].toLowerCase()) return false;
        }
        return true;
    }

    const resetState = (riddleWord) => {
        setCrossword(new Array(riddleWord.length))
        setCrosswordCopy([])
        setCrosswordIndex(0)
        setGuess({ isGuessMade: false, isCorrect: null })
        setIsSubmitEnabled(false)
        setGuessJustMade(null)
        setIsGameOver(false)
        setIsPopUpShown(false)
        setIsMessageAlertShown(false)
        setIsCrosswordRevealed(false)
        setIsSeeResultsShown(false)
        setNumberOfCluesRevealed(0)
        setIsOverlayShowing(false)
        setAreInstructionsShown(false)
        setCurrentClue(0)
        setGuessesRemaining(3)
        setCurrentMaxClue(0)
        setIsEndGameAnimationRunning(false)
        setPopUpMessage("")
        setCrosswordGuesses({
            guess0: [],
            guess1: [],
            guess2: [],
            guess3: []
        })
        setDidUserFailPuzzle(false)
        // localStorage.clear();
    }

    const getDelay = (delayAdded = 0) => {
        return (12 - currentMaxClue) * 2350 + delayAdded
    }

    // This function will jump to the last clue the user
    // Looked at before the end of game animation started
    // Ex. If the user had looked at all the clues
    // But then decides to go all the way back to the first
    // Clue and then complete the puzzle
    // This will jump them back to the last clue which happens
    const getCurrentClueForEndOfGame = () => {
        // If the we are not on the 0th clue
        // Or if the two clue values don't equal each other
        // If they do equal each other, then we never added
        // to the currentMaxClue in handleGetPreviousClue
        if (currentMaxClue >= 0 && currentClue !== currentMaxClue)
            return currentMaxClue - 1
        return currentMaxClue
    }

    // Show the remaining clues if the game is over
    if (isGameOver && isEndGameAnimationRunning) {
        if (currentClue !== 11)
            setTimeout(() => {
                handleGetNextClue()
            }, 2500)
    }

    const handleGameIsOver = async (result, shouldAnimationsBeShown = true) => {
        // Tell everyone that the game is over
        setIsGameOver(true)
        setLocalStorage("isGameOver", true)

        // Don't do any of the animations if the game is already over from localStorage
        if (shouldAnimationsBeShown) {
            // Send the result of the game to the backend for stats purposes
            // Only send this API if shouldAnimationsBeShown is true b/c we 
            // Don't want to resend the API every time the user reloads their game
            await submitPuzzle(puzzleNumber, { didUserFailPuzzle: !result, finalClue: currentClue, guessesRemaining: result ? guessesRemaining : guessesRemaining - 1 })

            // Start the end of game animation
            // Where we run through the remaining clues 
            // if there are any of them
            setTimeout(() => {
                setIsEndGameAnimationRunning(true)
            }, 4500);
            setLocalStorage("isEndGameAnimationRunning", false)

            // Set the current clue to the currentMaxClue
            // Or currentMaxClue-1
            setTimeout(() => {
                setCurrentClue(getCurrentClueForEndOfGame())
            }, 4500);
            setLocalStorage("currentClue", getCurrentClueForEndOfGame())
            // End the animation once all of the
            // Remaining clues have been shown
            setTimeout(() => {
                setIsEndGameAnimationRunning(false)
            }, getDelay(4500));

            // Show the correct word
            setTimeout(() => {
                // Only reveal the correct word
                // If the user ran out of clues
                // Ie. only if result is false
                if (!result) {
                    setIsCrosswordRevealed(true)
                }
            }, getDelay(5000))
            setLocalStorage("isCrosswordRevealed", true)

            // When the game is over
            // Show either the success or failure message
            // Depending on if the user succeeded or failed
            setTimeout(() => {
                if (isMobile) {
                    if (height > 600)
                        setIsMessageAlertShown(true)
                }
                else {
                    setIsMessageAlertShown(true)
                }
            }, "2500");

            // Show the results popup
            // Get rid of the "Next Time" or "Great Job"
            // Get rid of the crossword and the lives bubbles
            setTimeout(() => {
                setIsPopUpShown(true)
                setIsMessageAlertShown(false)
                setIsSeeResultsShown(true)
                // If the user guessed correctly
                // We don't need time to show the 
                // Correct word animation
            }, getDelay(result ? 5500 : 9500))
            setLocalStorage("isPopUpShown", true)
            setLocalStorage("isSeeResultsShown", true)
        }
        setCrosswordCopy([...crossword])
        setLocalStorage("crosswordCopy", [...crossword])

    }

    // Function called when the user presses the submit button
    // Determines if the user's guess was correct or incorrect
    // And takes appropriate actions based on guess & current # of lives
    const handleCrosswordSubmit = () => {
        // Check if the user is allowed to submit
        if (isSubmitEnabled) {
            // Disable the submit button
            setIsSubmitEnabled(false)

            // Add the users guess to the guess history
            crosswordGuesses[`guess${4 - guessesRemaining}`] = crossword
            setCrosswordGuesses({ ...crosswordGuesses })
            setLocalStorage("crosswordGuesses", { ...crosswordGuesses })

            // If they are able to submit, 
            // then check if their submission is correct
            const result = areArraysEqual(crossword, riddleWord)

            // Put the result in local storage
            setLocalStorage("result", result)

            //If the user guesses incorrectly
            if (!result) {
                // Tell everyone that a guess has been made
                // And that the guess was NOT correct
                setGuess({ isGuessMade: true, isCorrect: false })
                setLocalStorage("guess", { isGuessMade: true, isCorrect: false })

                // After 2 seconds, setGuessJustMade to false
                // This variable is relevant in CrosswordLetter.js
                // It allows the last if block to run
                // Which basically means that the blue square animation will run
                // Without this timeout, the crossword will become red immediately
                setTimeout(() => {
                    setGuessJustMade(false)
                }, "2000")
                setLocalStorage("guessJustMade", false)

                // After 4.5 seconds:
                // Remove the guess from state
                // Subtract one from the number of guesses remaining
                // SetGuessJustMade back to null
                // Clear the wrong guess from the crossowrd and go back to the first letter
                setTimeout(() => {
                    setGuess({ isGuessMade: false, isCorrect: null })
                    setGuessesRemaining(guessesRemaining - 1)
                    // Keep the guessJustMade as false if the game is over
                    if (!(guessesRemaining - 1 === 0)) {
                        setGuessJustMade(null)
                        setCrossword(new Array(riddleWord.length))
                        setLocalStorage("crossword", new Array(riddleWord.length))
                        setCrosswordIndex(0)
                        setLocalStorage("crosswordIndex", 0)
                    }
                }, "4500");
                // Don't put the local storage in the setTimeout
                setLocalStorage("guessesRemaining", guessesRemaining - 1)
                setLocalStorage("guess", { isGuessMade: false, isCorrect: null })
                setLocalStorage("guessJustMade", null)


                // If the user is out of guesses,
                // Then end the game
                if (guessesRemaining - 1 === 0) {
                    setDidUserFailPuzzle(true)
                    handleGameIsOver(result)
                }
            }
            // If user guesses correctly
            else {
                // Tell everyone that a guess has been made and it's correct
                setGuess({ isGuessMade: true, isCorrect: true })
                setLocalStorage("guess", { isGuessMade: true, isCorrect: true })

                // Needed for the green shake animation
                // Logic handled in CrosswordLetter.js
                setTimeout(() => {
                    setGuessJustMade(true)
                }, "2000")
                setLocalStorage("guessJustMade", true)

                // Set isGuessMade back to false
                setTimeout(() => {
                    setGuess({ isGuessMade: false, isCorrect: null })
                }, "4500");
                setLocalStorage("guess", { isGuessMade: false, isCorrect: null })

                // Call the handleIsCorrect function
                handleGameIsOver(result)
            }
        }
    }

    const handleGetPreviousClue = () => {
        if (currentClue === 0 || isLoading) { return }
        setCurrentClue(currentClue - 1)
        setLocalStorage("currentClue", currentClue - 1)

        if (currentMaxClue === currentClue) {
            setCurrentMaxClue(currentMaxClue + 1)
            setLocalStorage("currentMaxClue", currentMaxClue + 1)

        }
    }

    const handleGetNextClue = () => {
        // Don't go past the end of the array
        if (currentClue >= 11 || isLoading) { return }

        // Set the current clue to the next clue
        setCurrentClue(currentClue + 1)
        setLocalStorage("currentClue", currentClue + 1)
        // Increase the number of clues revealed
        // Used at the end to determine user's "score"
        if (!isGameOver)
            setNumberOfCluesRevealed(numberOfCluesRevealed + 1)
        setLocalStorage("numberOfCluesRevealed", numberOfCluesRevealed + 1)

        if (currentClue + 1 >= currentMaxClue) {
            setCurrentMaxClue(currentClue + 1)
            setLocalStorage("currentMaxClue", currentClue + 1)
        }
        // Don't let the user click a clue if the instructions are showing
        if (areInstructionsShown) { return }
        // Only change the copy clues if the game is not over
        // This is so we can present what the user finished
        // With for their results page
        if (!isGameOver) {
            areCluesShownCopy[currentClue + 1] = true
            setAreCluesShownCopy([...areCluesShownCopy])
            setLocalStorage("areCluesShownCopy", [...areCluesShownCopy])
        }

        areCluesShown[currentClue + 1] = true
        setAreCluesShown([...areCluesShown])
        setLocalStorage("areCluesShown", [...areCluesShown])
    }

    const handleSkipEndOfGameAnimation = () => {
        // End the animation
        setIsEndGameAnimationRunning(false)
        setTimeout(() => {
            // Set the currentClue and maxClue to the last clue
            setCurrentClue(11)
            setCurrentMaxClue(11)
            // Set all the clues to being shown
            setAreCluesShown([
                true, true, true, true, true,
                true, true, true, true, true,
                true, true, true, true, true,
                true, true, true, true, true,
            ])
        }, 2000);
        // If the user never guessed correctly
        // Show them the correct word
        if (guessesRemaining === 0) {
            setIsCrosswordRevealed(true)
            setLocalStorage("isCrosswordRevealed", true)
            // Show the results window
            // Delay it if the user needs to
            // Be shown the correct word animation
            setTimeout(() => {
                setIsPopUpShown(true)
                setIsSeeResultsShown(true)
                setLocalStorage("isSeeResultsShown", true)
                setIsMessageAlertShown(false)
            }, 3000);
            setLocalStorage("isPopUpShown", true)
        }
        else {
            setIsPopUpShown(true)
            setLocalStorage("isPopUpShown", true)
            setIsSeeResultsShown(true)
            setLocalStorage("isSeeResultsShown", true)
            setIsMessageAlertShown(false)
        }
    }

    const handleKeyDown = (e) => {
        if (!allowedKeyBoardEvent.includes(e.key) || guess.isGuessMade === true || isLoading) { return }

        // Delete
        if (e.key === "Backspace") {
            const letterBeforeDelete = crossword[crosswordIndex]
            crossword[crosswordIndex] = ""
            // Update state in Crossword component
            setCrossword([...crossword])
            //Update local storage
            setLocalStorage("crossword", [...crossword])
            // Update the index
            handleDelete(letterBeforeDelete)
        }
        // Left
        else if (e.key === "ArrowLeft") {
            // Don't go off of the beginning of the array
            if (crosswordIndex > 0) {
                setCrosswordIndex(crosswordIndex - 1)
                // Set local storage
                setLocalStorage("crosswordIndex", crosswordIndex - 1)

            }
        }
        // Right
        else if (e.key === "ArrowRight") {
            if (crosswordIndex < riddleWord.length - 1) {
                setCrosswordIndex(crosswordIndex + 1)
                // Set local storage
                setLocalStorage("crosswordIndex", crosswordIndex + 1)
            }
        }
        // Letter or number
        else {
            const letterBeforeChange = crossword[crosswordIndex]
            crossword[crosswordIndex] = e.key
            // Update state in Crossword component
            setCrossword([...crossword])
            //Update local storage
            setLocalStorage("crossword", [...crossword])
            // Updated state in GameBoard component
            findNextOpenBox(letterBeforeChange)
        }
    }

    const handleDelete = (letterBeforeDelete) => {
        // If there was a word before we pressed delete
        // Then keep the index the same
        if (!letterBeforeDelete) {
            // Otherwise decrease the index by 1 and delete the 
            // letter at that index, as long as we're not on the first index
            if (crosswordIndex !== 0) {
                crossword[crosswordIndex - 1] = ""
                setCrossword([...crossword])
                //Update local storage
                setLocalStorage("crossword", [...crossword])
                setCrosswordIndex(crosswordIndex - 1)
                // Set local storage
                setLocalStorage("crosswordIndex", crosswordIndex - 1)
            }
        }
    }

    const findNextOpenBox = (letterBeforeChange) => {
        // If the box we are filled in was not previously empty,
        // Then increment the index by 1
        // Unless we are on the end. If we are at the end, continue as usual
        if (letterBeforeChange) {
            if (!(crosswordIndex === riddleWord.length - 1)) {
                setCrosswordIndex(crosswordIndex + 1)
                // Set local storage
                setLocalStorage("crosswordIndex", crosswordIndex + 1)
                return
            }
        }
        // Find the next open box by
        // Looking for the rightmost open box after you
        // And if all those are full, the left most open box before you
        let openBoxIndex = -1
        for (let i = crosswordIndex; i < riddleWord.length; ++i) {
            if (!crossword[i]) {
                openBoxIndex = i
                // Break after we find the first instance of an open box
                break;
            }
        }
        // If openBoxIndex is still -1, then we haven't found an open
        // box to the right of us
        // Which means we need to look left
        if (openBoxIndex === -1) {
            for (let i = crosswordIndex; i >= 0; --i) {
                if (!crossword[i]) {
                    // Keep finding the leftmost open box
                    openBoxIndex = i
                }
            }
        }
        // If openBoxIndex is still -1, that means all the boxes are filled
        // If we are on the last box, or the box was empty, just stay there
        // If the box already has a letter in it, then move one to the right
        if (openBoxIndex === -1) {
            if (crosswordIndex === riddleWord.length - 1 || !letterBeforeChange) {
                return
            }
            else {
                setCrosswordIndex(crosswordIndex + 1)
                // Set local storage
                setLocalStorage("crosswordIndex", crosswordIndex + 1)
            }
        }
        else {
            // Otherwise, we should have found the correct open box
            setCrosswordIndex(openBoxIndex)
            // Set local storage
            setLocalStorage("crosswordIndex", openBoxIndex)
        }
    }

    const updateCrosswordMobile = (letter) => {
        // If the letter is "", then we are trying to delete
        // So we want to subtract from the index
        const addOrDeleteLetter = letter ? "add" : "delete"

        if (addOrDeleteLetter === "add") {
            const letterBeforeChange = crossword[crosswordIndex]
            crossword[crosswordIndex] = letter
            // Update state in Crossword component
            setCrossword([...crossword])
            //Update local storage
            setLocalStorage("crossword", [...crossword])
            // Updated state in GameBoard component
            findNextOpenBox(letterBeforeChange)
        }
        else {
            const letterBeforeDelete = crossword[crosswordIndex]
            crossword[crosswordIndex] = ""
            // Update state in Crossword component
            setCrossword([...crossword])
            //Update local storage
            setLocalStorage("crossword", [...crossword])
            // Updated state in GameBoard component
            handleDelete(letterBeforeDelete)
        }
    }

    const handleGuess = () => {
        // If the user guesses correctly
        if (guess.isCorrect) {
            return "crossword-correct-animation"
        }
        // If we are showing the correct answer when the user is
        // Out of lives
        else if (isGameOver && isCrosswordRevealed) {
            return "reveal-crossword-animation"
        }
        // If the user guessed incorrectly
        else {
            return "crossword-incorrect-animation"
        }
    }

    const handleCrosswordAnimation = (index) => {
        const animationDurationTime = 3 / riddleWord.length
        const delayTime = (1 / riddleWord.length) * (index + 1)
        return {
            animation: "pulse",
            animationDuration: `${animationDurationTime}s`,
            animationDelay: `${delayTime}s`,
            backgroundColor: "#68b4f3",
            width: isMobile ? Math.floor(width / riddleWord.length) - 2 : null
        }
    }

    const isBoxThird = (index) => {
        if ((index + 1) % 3 === 0) {
            return "third-box"
        }
    }

    const getStatsStyle = (index) => {
        const scale = height < 600 && isMobile ? 200 : 300
        const scaleFactor = scale / (Math.max(...Object.values(stats.successes), stats.failures))
        if (index === 12) {
            if (stats.failures === 0) {
                if (isMobile)
                    return { height: 5 }
                else
                    return { height: 5, marginLeft: 10 }
            }
            return {
                height: scaleFactor * stats.failures,
            }
        }
        else if (stats.successes[index] === 0) {
            return { height: 5 }
        }
        return {
            height: scaleFactor * stats.successes[index]

        }
    }

    const getGameDirections = () => {
        if (areStatsShown) {
            return "See how you stack up against the field."
        }
        else if (isGameOver && isSeeResultsShown && isMobile) {
            return "Click on a clue to see its explanation!"
        }
        else if (isGameOver && isSeeResultsShown) {
            return "Hover over a clue to see its explanation!"
        }
        else {
            return "Use the fewest clues to guess the correct word!"
        }
    }

    const getCrosswordContainerStyle = () => {
        // If the game is over and the end of game animation is NOT running
        // Move the crossword container up towards the top of the screen
        if (isGameOver && isSeeResultsShown) {
            return {
                marginTop: 45
            }
        }
        else {
            if (height < 600) {
                return {
                    position: "absolute",
                    left: 0,
                    right: 0,
                    marginLeft: "auto",
                    marginRight: "auto",
                    bottom: 160,
                }
            }
            return {
                position: "absolute",
                left: 0,
                right: 0,
                marginLeft: "auto",
                marginRight: "auto",
                bottom: 200
            }
        }
    }

    return (
        <>
            {isOverlayShowing ?
                <Overlay
                    puzzleAuthor={puzzleAuthor}
                    setIsOverlayShowing={setIsOverlayShowing} />
                : null}
            {areInstructionsShown ? isMobile ?
                <MobileInstructions
                    setLocalStorage={setLocalStorage}
                    setAreInstructionsShown={setAreInstructionsShown} /> :
                <Instructions
                    setLocalStorage={setLocalStorage}
                    setAreInstructionsShown={setAreInstructionsShown} />
                : null}
            {isGameOver && isMessageAlertShown ?
                <MessageAlert
                    setLocalStorage={setLocalStorage}
                    guessesRemaining={guessesRemaining}
                    numberOfCluesRevealed={numberOfCluesRevealed}
                    setPopUpMessage={setPopUpMessage}
                    popUpMessage={popUpMessage}
                    type={"gameOverMessage"}
                    didUserFailPuzzle={didUserFailPuzzle}
                /> : null}
            <div className='game-header'>
                <div
                    style={{ justifyContent: width <= 430 ? puzzleNumber !== newestPuzzleNumber ? null : "center" : null }}
                    className='flex-or-grid'>
                    {isMobile ?
                        <div
                            style={{ display: height < 600 ? 'none' : null }}
                            className='header-container'>
                            <div>
                                {puzzleNumber !== newestPuzzleNumber ?
                                    <button
                                        onClick={() => {
                                            navigate(`/?puzzleNumber=${newestPuzzleNumber}`)
                                            navigate(0)
                                        }}
                                        className='play-newest-puzzle-button'>
                                        Play new puzzle
                                    </button>
                                    : null}
                            </div>
                            {isMobile ?
                                <p className='puzzle-number-mobile'>
                                    <span className='mobile-date'>
                                        {releaseDate ? new Date(releaseDate?._seconds * 1000 + releaseDate?._nanoseconds / 1000000).toLocaleDateString("en", {
                                            month: "numeric",
                                            day: "numeric",
                                        }) : new Date().toLocaleDateString("en", {
                                            month: "numeric",
                                            day: "numeric",
                                        })}
                                    </span>
                                    #{puzzleNumber}
                                </p>
                                : null}
                            <p className='game-header-name'>Riddley</p>
                            <div className='game-date'>{releaseDate ? new Date(releaseDate?._seconds * 1000 + releaseDate?._nanoseconds / 1000000).toLocaleDateString("en", {
                                month: "long",
                                day: "numeric",
                                year: "numeric"
                            }) : new Date().toLocaleDateString("en", {
                                month: "long",
                                day: "numeric",
                                year: "numeric"
                            })}
                                <span className='beta'>Puzzle #{puzzleNumber}</span>
                            </div>
                            <FontAwesomeIcon
                                onClick={() => setAreInstructionsShown(true)} icon={faCircleQuestion}
                                className="how-to-play-icon-mobile" />
                        </div>
                        :
                        <>
                            <p className='game-header-name'>Riddley</p>
                            <div className='game-date'> {releaseDate ? new Date(releaseDate?._seconds * 1000 + releaseDate?._nanoseconds / 1000000).toLocaleDateString("en", {
                                month: "long",
                                day: "numeric",
                                year: "numeric"
                            }) : new Date().toLocaleDateString("en", {
                                month: "long",
                                day: "numeric",
                                year: "numeric"
                            })}
                                <span className='beta'>Puzzle #{puzzleNumber}</span>
                                {puzzleNumber !== newestPuzzleNumber && !isLoading ?
                                    <button
                                        onClick={() => {
                                            navigate(`/?puzzleNumber=${newestPuzzleNumber}`)
                                            navigate(0)
                                        }}
                                        className='play-newest-puzzle-button'>
                                        Play newest puzzle
                                    </button>
                                    : null}
                            </div>
                            <FontAwesomeIcon onClick={() => setAreInstructionsShown(true)} icon={faCircleQuestion} className="how-to-play-icon" />
                        </>
                    }
                </div>
            </div>
            {
                isPopUpShown ?
                    <PopUp
                        didUserFailPuzzle={didUserFailPuzzle}
                        isPopUpShown={isPopUpShown}
                        setIsPopUpShown={setIsPopUpShown}
                        guessJustMade={guessJustMade}
                        riddleWord={riddleWord}
                        crosswordCopy={crosswordCopy}
                        crosswordGuesses={crosswordGuesses}
                        currentMaxClue={currentMaxClue}
                        popUpMessage={popUpMessage}
                        resetState={resetState}
                        setAreStatsShown={setAreStatsShown}
                    /> : null
            }
            <div className={isPopUpShown || areInstructionsShown ? "blur" : null}>
                {isMobile && isMessageAlertShown ? <p className='game-directions-invisible'></p> :
                    <p className='game-directions'>{getGameDirections()}
                    </p>}
                {areStatsShown ? null :
                    <div style={{ position: "relative", margin: "0 auto", marginTop: height < 600 ? 25 : 0 }}>
                        <div className='clueTable'>
                            <Clue
                                currentClue={currentClue}
                                clueDescription={clues[currentClue]?.clueDescription}
                                clue={clues[currentClue]?.clueText}
                                isEndGameAnimationRunning={isEndGameAnimationRunning}
                                areInstructionsShown={areInstructionsShown}
                                numberOfCluesRevealed={numberOfCluesRevealed}
                                handleGetNextClue={handleGetNextClue}
                                handleGetPreviousClue={handleGetPreviousClue}
                                guess={guess}
                                isGame0ver={isGameOver}
                                isSeeResultsShown={isSeeResultsShown}
                                question={question}
                            />
                        </div>
                    </div>}

                {areStatsShown ?
                    <div className='stats-container'>
                        {[...Array(13).keys()].map((index) => {
                            return <div
                                className={`stats-bar color-${Math.floor(index / 3)}`}
                                style={getStatsStyle(index)}
                            >
                            </div>
                        })}
                    </div>
                    : null}
                <div className={isMobile ? "flex-center score-container" : 'flex-center score-container'}>
                    {[...Array(areStatsShown ? 13 : 12).keys()].map((index) => {
                        return (
                            <div
                                key={index}
                                onClick={() => {
                                    if (areCluesShown[index]) {
                                        if (currentMaxClue === currentClue) {
                                            setCurrentMaxClue(currentMaxClue + 1)
                                            setLocalStorage("currentMaxClue", currentMaxClue + 1)
                                        }
                                        setCurrentClue(index)
                                        setLocalStorage("currentClue", index)

                                    }
                                }}
                                className={index === currentClue && areCluesShown[currentClue] && currentClue >= currentMaxClue ? `question-boxes question-box-animation ${areStatsShown ? '' : 'grey-delay'} color-${Math.floor(index / 3)} ${isBoxThird(index)}`
                                    : index === currentClue && areCluesShown[index] ? `question-boxes ${index === 12 ? 'color-4-selected' : 'question-box-selected'} ${isBoxThird(index)}`
                                        : areCluesShown[index] ? `question-boxes ${areStatsShown ? `` : `grey-no-delay`} color-${Math.floor(index / 3)} ${isBoxThird(index)}`
                                            : `question-boxes color-${Math.floor(index / 3)} ${isBoxThird(index)}`}>
                                <span className='stat-text'>{areStatsShown ? `${Math.round((((index === 12 ? stats.failures : stats.successes[index]) / (stats.submissions !== 0 ? stats.submissions : 1))) * 100)}%` : null}</span>
                            </div>
                        )
                    })}
                </div>

                {isMobile ?
                    <div className="crossword-keyboard-container">
                        <div
                            style={getCrosswordContainerStyle()}
                        >
                            <div
                                className={guess.isGuessMade || (isGameOver && isCrosswordRevealed) ? handleGuess() : null}
                                style={{ display: "flex", justifyContent: 'center', position: 'relative' }}>
                                {[...Array(riddleWord.length).keys()].map((index) => {
                                    return <CrosswordLetter
                                        key={index}
                                        guess={guess} localStorageValue
                                        guessJustMade={guessJustMade}
                                        index={index}
                                        handleCrosswordAnimation={handleCrosswordAnimation}
                                        isGameOver={isGameOver}
                                        isCrosswordRevealed={isCrosswordRevealed}
                                        riddleWord={riddleWord}
                                        crossword={crossword}
                                        crosswordIndex={crosswordIndex}
                                        setCrosswordIndex={setCrosswordIndex}
                                    />
                                })}
                            </div>
                        </div>
                        <Keyboard
                            guess={guess}
                            updateCurrentWordMobile={updateCrosswordMobile}
                            handleCrosswordSubmit={handleCrosswordSubmit}
                            isSubmitEnabled={isSubmitEnabled}
                            isGameOver={isGameOver}
                            isSeeResultsShown={isSeeResultsShown}
                            guessesRemaining={guessesRemaining}
                            handleSkipEndOfGameAnimation={handleSkipEndOfGameAnimation}
                            isEndGameAnimationRunning={isEndGameAnimationRunning}
                            handleGetNextClue={handleGetNextClue}
                            handleGetPreviousClue={handleGetPreviousClue}
                            currentClue={currentClue}
                        />
                    </div>
                    : <>
                        <div
                            className={guess.isGuessMade || (isGameOver && isCrosswordRevealed) ? handleGuess() : null}
                            style={{ display: "flex", justifyContent: 'center', marginTop: 20 }}>
                            {[...Array(riddleWord.length).keys()].map((index) => {
                                return <CrosswordLetter
                                    key={index}
                                    guess={guess} localStorageValue
                                    guessJustMade={guessJustMade}
                                    index={index}
                                    handleCrosswordAnimation={handleCrosswordAnimation}
                                    isGameOver={isGameOver}
                                    isCrosswordRevealed={isCrosswordRevealed}
                                    riddleWord={riddleWord}
                                    crossword={crossword}
                                    crosswordIndex={crosswordIndex}
                                    setCrosswordIndex={setCrosswordIndex}
                                    width={width}
                                />
                            })}
                        </div>
                    </>}
                {/* Only show the guesses remaining and the submit button if the game is ongoing */}
                {!isGameOver || !isSeeResultsShown ?
                    <>
                        {isMobile ? null : <div className='flex-center'>
                            <p className='guesses-remaining'>Guesses remaining:</p>
                        </div>}
                        {isMobile ? null : <div className='flex-center guesses-container'>
                            {[...Array(4).keys()].map((index) => {
                                //If the number of clues remaining is less than your index
                                // Then you are invisible
                                if (index >= guessesRemaining) {
                                    return <span key={index} className='guesses-remaining-bubble guess-invisble'></span>
                                }
                                // If we are on the last clue and guess is false
                                // Then add the animation class to the guess
                                if (index === guessesRemaining - 1 && guess.isGuessMade && !guess.isCorrect) {
                                    return <span key={index} className='guesses-remaining-bubble lose-guess-animation'></span>
                                }
                                return <span key={index} className='guesses-remaining-bubble'></span>
                            })}
                        </div>}

                        {isMobile ? null :
                            <>
                                <div className='flex-center gap-submit'>
                                    <button
                                        onClick={isEndGameAnimationRunning || guess.isGuessMade || isLoading ? null : handleGetPreviousClue}
                                        className={currentClue !== 0 && !isGameOver && !guess.isGuessMade && !isLoading ? 'submit' : "submit disable-click"}>
                                        Previous
                                    </button>
                                    <button
                                        onClick={isEndGameAnimationRunning || guess.isGuessMade || isLoading ? null : handleGetNextClue}
                                        className={currentClue < 11 && !isGameOver && !guess.isGuessMade && !isLoading ? 'submit' : "submit disable-click"}>
                                        Next
                                    </button>
                                </div>
                                <div
                                    style={{ gap: 20 }}
                                    className='flex-center'>
                                    {isEndGameAnimationRunning ? null : <button
                                        onClick={isEndGameAnimationRunning || isLoading ? null : handleCrosswordSubmit}
                                        className={isSubmitEnabled && !isLoading ? 'submit submit-color submit-border' : "submit disable-click"}
                                        style={{ color: "white" }}>
                                        Submit
                                    </button>}
                                    {isEndGameAnimationRunning ?
                                        <button
                                            onClick={handleSkipEndOfGameAnimation}
                                            className={'skip-to-results'}>
                                            Skip to results
                                        </button>
                                        : null
                                    }
                                </div>
                            </>
                        }
                    </> :
                    <>
                        <div
                            style={{ marginTop: 10, }}
                            className={'view-results-grid'}>
                            {isMobile && isGameOver ?
                                <FontAwesomeIcon
                                    onClick={handleGetPreviousClue}
                                    className={currentClue !== 0 ? 'mobile-arrow-game-over' : 'mobile-arrow-game-over arrow-disabled'}
                                    icon={faCircleLeft}
                                />
                                : <button
                                    onClick={isEndGameAnimationRunning ? null : handleGetPreviousClue}
                                    className={currentClue !== 0 && !isEndGameAnimationRunning ? 'view-results' : "view-results disable-click"}>
                                    Previous
                                </button>}
                            <button onClick={() => setIsPopUpShown(true)} className='view-results'>View Results</button>
                            {isMobile && isGameOver ?
                                <FontAwesomeIcon
                                    onClick={handleGetNextClue}
                                    className={currentClue < 11 ? 'mobile-arrow-game-over' : 'mobile-arrow-game-over arrow-disabled'}
                                    icon={faCircleRight}
                                />
                                : <button
                                    onClick={isEndGameAnimationRunning ? null : handleGetNextClue}
                                    className={currentClue < 11 && !isEndGameAnimationRunning ? 'view-results' : "view-results disable-click"}>
                                    Next
                                </button>}
                        </div>
                        <div>
                            <button
                                onClick={async () => {
                                    setAreStatsShown(!areStatsShown)
                                    if (!areStatsShown) {
                                        const response = await getStats(puzzleNumber);
                                        if (response.success && response.data.submissions !== 0) {
                                            setStats(response.data);
                                        }
                                    }
                                }
                                }
                                className='view-results view-stats'>
                                {areStatsShown ? "Close stats" : "View stats"}
                            </button>
                        </div>
                    </>
                }
            </div >
        </>

    )
}

export default GameBoard
