import React, { useEffect, useContext, useState } from 'react'
import "../Styles/popUp.css"
import { useNavigate, createSearchParams, useLocation } from "react-router-dom"
import { getPuzzleByNumber } from '../Api/api';
import { isMobile } from 'react-device-detect';
import { ContextProvider } from "../context"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CountDownClock from './CountdownClock'
import emojis from "../utils/emojis"
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import MessageAlert from './MessageAlert'
import utils from "../utils"
const { FRONTEND_URL, CREATE_PUZZLE_URL } = require("../configs/activebuildconfig.json")



const PopUp = ({ isPopUpShown, setIsPopUpShown, guessJustMade,
    riddleWord, crosswordCopy, crosswordGuesses, currentMaxClue,
    popUpMessage, resetState, didUserFailPuzzle, setAreStatsShown }) => {

    const navigate = useNavigate()

    const search = useLocation().search;

    const puzzleNumberQueryParam = new URLSearchParams(search).get('puzzleNumber');

    const { areCluesShownCopy, puzzleNumber, newestPuzzleNumber, resetContext } = useContext(ContextProvider)

    const [isCopyToClipboardMessageShown, setIsCopyToClipboardMessageShown] = useState(false)

    const [nextPuzzleNumberToBePlayed, setNextPuzzleNumberToBePlayed] = useState(puzzleNumber > 1 ? puzzleNumber - 1 : puzzleNumber + 1)

    const [isGetNextGameClicked, setIsGetNextGameClicked] = useState(false)

    const [isNextPuzzleNumberChanged, setIsNextPuzzleNumberChanged] = useState(false)

    const [emoji, setEmoji] = useState(didUserFailPuzzle ? "😔" : "😀")

    const [height, setHeight] = useState(window.innerHeight)

    const handleWindowSizeChange = () => {
        setHeight(window.innerHeight)
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const handleGetBoxColorClassName = (index, returnEmojis = false) => {
        //Get the row and column of the index
        const row = Math.floor(index / 3)

        // If the clue was revealed, get the correct row color
        if (!areCluesShownCopy[index]) {
            if (returnEmojis) {
                switch (row) {
                    case 0:
                        return "🟪";
                    case 1:
                        return "🟧"
                    case 2:
                        return "🟦"
                    case 3:
                        return "🟩"
                    default:
                        return "⬜";
                }
            }
            else {
                return `grid-box grid-box-${row}`
            }
        }
        // If the clue was not revealed, return default color
        else {
            if (returnEmojis) {
                return "⬜";
            }
            else {
                return "grid-box grid-box-gray"
            }
        }
    }
    const handleRedOrGreenBox = (i, crosswordGuess) => {
        if (riddleWord[i]?.toLowerCase() === crosswordGuess[i]?.toLowerCase()) {
            return "grid-box-green"
        }
        else {
            return "grid-box-red"
        }
    }

    const areAllCrossWordGuessesUsed = () => {
        return crosswordGuesses[`guess1`].length !== 0 && crosswordGuesses[`guess2`].length !== 0 && crosswordGuesses[`guess3`].length !== 0
    }

    const returnPuzzleNumber = () => {
        let emojiLength = riddleWord.length < 10 ? riddleWord.length : 10
        let numberOfSpaces = (emojiLength - 3) * 1.5 + (emojiLength - 3)
        const stringToReturn = `Puzzle #${puzzleNumber}`
        return stringToReturn.padStart(numberOfSpaces + stringToReturn.length, " ")
    }

    const return20Questions = () => {
        let emojiLength = riddleWord.length < 10 ? riddleWord.length : 10
        let numberOfSpaces = 2 + (emojiLength - 3) * 1.5 + (emojiLength - 3)
        const stringToReturn = "Riddley"
        return stringToReturn.padStart(numberOfSpaces + stringToReturn.length, " ")
    }

    const getEmojiTableSpaces = () => {
        let emojiLength = riddleWord.length < 10 ? riddleWord.length : 10
        const paddingStringToReturn = ""
        const numberOfSpaces = (emojiLength - 3) * 1.5 + (emojiLength - 3)
        return paddingStringToReturn.padStart(numberOfSpaces, " ")
    }

    const organizeResults = () => {
        const returnEmojis = true
        // Loop through the game board and return the correct
        // Emoji color that the user ended with on their board
        const emojiBoard = [...Array(12).keys()].map((index) => {
            return handleGetBoxColorClassName(index, returnEmojis)
        })

        const crosswordEmjois = [...Array(riddleWord.length).keys()].map((index) => {
            return emoji
        })
        emojiBoard.push(crosswordEmjois)
        emojiBoard.push(isMobile ? null : "\n")
        emojiBoard.splice(3, 0, "\n")
        emojiBoard.splice(7, 0, "\n")
        emojiBoard.splice(11, 0, "\n")
        emojiBoard.splice(15, 0, "\n")
        emojiBoard.splice(0, 0, getEmojiTableSpaces())
        emojiBoard.splice(5, 0, getEmojiTableSpaces())
        emojiBoard.splice(10, 0, getEmojiTableSpaces())
        emojiBoard.splice(15, 0, getEmojiTableSpaces())
        emojiBoard.unshift("\n")
        emojiBoard.unshift(returnPuzzleNumber())
        emojiBoard.unshift("\n")
        emojiBoard.unshift(return20Questions())
        return emojiBoard.toString().replace(/,/g, '')
    }

    const shareResults = () => {
        if (navigator.share) {
            navigator
                .share({
                    text: organizeResults()
                })
                .then(() => {
                    console.log("Successfully shared");
                })
                .catch((error) => {
                    console.error("Something went wrong", error);
                });
        }
    };

    const shareGame = () => {
        if (navigator.share) {
            navigator
                .share({
                    text: puzzleNumberQueryParam ? `${FRONTEND_URL}/?puzzleNumber=${puzzleNumberQueryParam}` : FRONTEND_URL
                })
                .then(() => {
                    console.log("Successfully shared");
                })
                .catch((error) => {
                    console.error("Something went wrong", error);
                });
        }
    };

    const getCrosswordResultStyle = () => {
        let numAutos = ""
        for (let i = 0; i < riddleWord.length; ++i) {
            numAutos += " auto"
        }
        return {
            gridTemplateColumns: numAutos
        }
    }

    const getNewGame = async () => {
        setIsGetNextGameClicked(true);
        setAreStatsShown(false)
        // Clear all the outstanding setTimeOuts
        var id = window.setTimeout(function () { }, 0);
        while (id--) {
            window.clearTimeout(id);
        }
        let result;
        // If the puzzle we want to play is currently active, then we don't need to pass a puzzle number to backend
        if (nextPuzzleNumberToBePlayed === newestPuzzleNumber) {
            result = await getPuzzleByNumber()
        } else {
            result = await getPuzzleByNumber(nextPuzzleNumberToBePlayed)
        }
        if (!result.success) {
            console.log("error getting previous game")
        }
        else {
            const { riddleWord, clues, question, puzzleAuthor, puzzleNumber, newestPuzzleNumber, releaseDate } = result.data
            resetState(utils.decryptString(riddleWord))
            localStorage.clear();
            resetContext(riddleWord, clues, question, puzzleAuthor, puzzleNumber, newestPuzzleNumber, releaseDate)
            navigate({
                search: createSearchParams({
                    puzzleNumber
                }).toString()
            })
        }
    }


    return isPopUpShown ? (
        <div className='pop-up'>
            {isCopyToClipboardMessageShown ?
                <MessageAlert type={"copiedToClipboard"} delayTime={1000} />
                : null}
            <FontAwesomeIcon onClick={() => setIsPopUpShown(false)} icon={faXmark} className="close-pop-up" />
            <p className='next-time-pop-up-header-text fun-font'>{popUpMessage}</p>
            <div className='color-grid'>
                {[...Array(12).keys()].map((index) => {
                    return <div
                        key={index}
                        className={handleGetBoxColorClassName(index)}>
                    </div>
                })}
            </div>
            <div className='crossword-result' style={getCrosswordResultStyle()}>
                {[...Array(4).keys()].map((guessNum) => {
                    return [...Array(riddleWord.length).keys()].map((letterIndex) => {
                        return crosswordGuesses[`guess${guessNum}`].length === 0 ? null
                            : < p
                                key={letterIndex}
                                className={handleRedOrGreenBox(letterIndex, crosswordGuesses[`guess${guessNum}`])}>
                                {crosswordGuesses[`guess${guessNum}`][letterIndex]?.toUpperCase()}
                            </p>
                    })
                })}
            </div>
            <p className='next-puzzle-in'>NEXT PUZZLE IN:</p>
            <CountDownClock />
            <div className='center-flex share-buttons'>
                {!isMobile ?
                    <div>
                        <CopyToClipboard text={organizeResults()}
                            onCopy={() => {
                                setIsCopyToClipboardMessageShown(true)
                                setTimeout(() => {
                                    setIsCopyToClipboardMessageShown(false)
                                }, 1300);
                            }}>
                            <button
                                className='share-results-button'>
                                Share Results
                            </button>
                        </CopyToClipboard>
                        <select
                            className='select-emoji'
                            onChange={(e) => {
                                setEmoji(e.target.value)
                            }}>
                            {[didUserFailPuzzle ? <option selected="selected" value={"😔"}>{"😔"}</option> : <option selected="selected" value={"😀"}>{"😀"}</option>, ...emojis.map((emoji) => {
                                return <option value={emoji}>{emoji}</option>
                            })]}
                        </select>
                    </div> :
                    <>
                        <button
                            onClick={() => shareResults()}
                            className='share-results-button share-results'>
                            Share Results
                        </button>
                        <select
                            className='select-emoji select-emoji-mobile'
                            onChange={(e) => {
                                setEmoji(e.target.value)
                            }}>
                            {[didUserFailPuzzle ? <option selected="selected" value={"😔"}>{"😔"}</option> : <option selected="selected" value={"😀"}>{"😀"}</option>, ...emojis.map((emoji) => {
                                return <option value={emoji}>{emoji}</option>
                            })]}
                        </select>
                    </>
                }
                {!isMobile ? <CopyToClipboard text={puzzleNumberQueryParam ? `${FRONTEND_URL}/?puzzleNumber=${puzzleNumberQueryParam}` : FRONTEND_URL}
                    onCopy={() => {
                        setIsCopyToClipboardMessageShown(true)
                        setTimeout(() => {
                            setIsCopyToClipboardMessageShown(false)
                        }, 1300);
                    }}>
                    <div>
                        <button
                            className='share-button share'>
                            Share Game
                        </button>
                    </div>
                </CopyToClipboard> :
                    <button
                        onClick={() => shareGame()}
                        className='share-button share'>
                        Share Game
                    </button>
                }
            </div>
            {/* Don't let the user play another game until the last clue has been shown */}
            <div className='flex-center' style={{ gap: 0 }}>
                <div
                    onClick={() => currentMaxClue >= 11 ? getNewGame() : null}
                    className={isNextPuzzleNumberChanged ? 'play-yesterdays-game-selected' : 'play-yesterdays-game'}>
                    <p className='play-puzzle-text'>
                        {isNextPuzzleNumberChanged ? "Click to play!" : isGetNextGameClicked ? 'Loading new game...' : 'Play puzzle #'}
                    </p>
                </div>
                <select
                    className='select-puzzle-number'
                    onChange={(e) => {
                        setNextPuzzleNumberToBePlayed(e.target.value)
                        setIsNextPuzzleNumberChanged(true)
                    }}>
                    {[...Array(newestPuzzleNumber).keys()].map((index) => {
                        if ((index + 1) !== puzzleNumber) {
                            return (
                                <option
                                    key={index}
                                    value={index + 1}
                                >
                                    {index + 1}
                                </option>
                            )
                        }
                        return null;
                    }).reverse()}
                </select>
            </div>
            <button
                onClick={() => setIsPopUpShown(false)}
                className='close-results'>
                Close Results
            </button>
            {/* Only show the "Make your own puzzle" link when the users screen height is big enough, 
            they are not on mobile device, and they got 3 guesses wrong */}
            {height < 800 && !isMobile && areAllCrossWordGuessesUsed() ? null : <a
                href={CREATE_PUZZLE_URL} target='_blank' rel="noreferrer">
                <p className='make-your-own-puzzle-link'>Didn't like that puzzle? Make your own.</p>
            </a>}
        </div>
    ) : null
}

export default PopUp
